<template>
  <div id="main">
    <div id="banner">
      <img class="banner-luolan" :src='require("@/assets/img/product/luolan/luolan.png")'>
      <p class="banner-title">洛澜盆底康复治疗仪</p>
      <div class="vi-symbol banner-symbol"></div>
      <p class="banner-subTitle">多维修复 医疗级诊疗方案</p>
      <p class="banner-verify">
        南京麦澜德医疗科技股份有限公司<br />
        医疗器械注册证编号：苏械注准20222090944 广告审查批准文号：苏械广审(文)第241213-22384号<br />
        请仔细阅读产品说明书或者在医务人员的指导下购买和使用<br />
        禁忌内容或者注意事项详见说明书<br />
      </p>
    </div>
    <div id="apply" class="content-item">
      <p class="apply-title">洛澜盆底康复治疗仪</p>
      <p class="apply-subTitle">治疗盆底肌+腹直肌 增强私密功能</p>
      <div class="vi-symbol apply-symbol"></div>
      <p class="apply-content">/ 适用于 /</p>
      <div class="apply-for">
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_1.png")' />
          <span class="for-item-text">治疗压力<br />性尿失禁</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_2.png")' />
          <span class="for-item-text">治疗盆腔<br />器官脱垂</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_3.png")' />
          <span class="for-item-text">缓解慢性<br />盆腔疼痛</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_4.png")' />
          <span class="for-item-text">产后盆底<br />功能康复</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_5.png")' />
          <span class="for-item-text">治疗腹直<br />肌分离</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_6.png")' />
          <span class="for-item-text">治疗阴道<br />松弛症</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_7.png")' />
          <span class="for-item-text">增强阴道<br />收缩功能</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/luolan/disease_8.png")' />
          <span class="for-item-text">治疗女性<br />性功能障碍</span>
        </div>
      </div>
    </div>
    <div id="technology" class="content-item">
      <span class="technology-title">生电联合Pro</span>
      <span class="technology-subTitle">三重技术赋能产后康复</span>
      <div class="vi-symbol technology-symbol"></div>
      <img class="technology-img" :src='require("@/assets/img/product/luolan/technology_img.png")' />
    </div>
    <div id="dimension" class="content-item">
      <span class="dimension-title">三维度医养护</span>
      <span class="dimension-subTitle">呵护产后妈妈的蜕变</span>
      <div class="vi-symbol dimension-symbol"></div>
      <img class="dimension-img" :src='require("@/assets/img/product/luolan/dimension_img.png")' />
      <img class="dimension-img-append" :src='require("@/assets/img/product/luolan/dimension_img_append.png")' />
    </div>
    <div id="model" class="content-item">
      <span class="model-title">一维·盆底康复</span>
      <span class="model-subTitle">稳固盆底肌力 悦享自在生活</span>
      <div class="vi-symbol model-symbol"></div>
      <p class="model-content">CACT盆底康复模型，循序渐进，<br />科学修复盆底</p>
      <img class="model-img" :src='require("@/assets/img/product/luolan/model_img.png")' />
      <img class="model-img-append" :src='require("@/assets/img/product/luolan/model_img_append.png")' />
    </div>
    <div id="monitor" class="content-item">
      <span class="monitor-title">监测腹肌 指导正确发力</span>
      <div class="vi-symbol monitor-symbol"></div>
      <p class="monitor-content">实时反馈腹肌参与情况，引导盆底正确收缩</p>
      <img class="monitor-img" :src='require("@/assets/img/product/luolan/monitor_img.png")' />
      <img class="monitor-img-append" :src='require("@/assets/img/product/luolan/monitor_img_append.png")' />
    </div>
    <div id="ck" class="content-item">
      <span class="ck-title">二维·产康修护</span>
      <span class="ck-subTitle">修复腹直肌 塑造曲线美学</span>
      <div class="vi-symbol ck-symbol"></div>
      <p class="ck-content">EMS电刺激，仿人手按摩，深度舒缓肌肉疲劳<br />帮助紧致塑形，促进神经兴奋、局部血液循环</p>
      <img class="ck-img" :src='require("@/assets/img/product/luolan/ck_img.png")' />
      <img class="ck-img-append" :src='require("@/assets/img/product/luolan/ck_img_append.png")' />
    </div>
    <div id="point" class="content-item">
      <span class="point-title">多点选择 全身享受</span>
      <div class="vi-symbol point-symbol"></div>
      <p class="point-content">作用身体多部位，由内而外，全面修护</p>
      <img class="point-img" :src='require("@/assets/img/product/luolan/point_img.png")' />
      <div class="point-one">
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_1.png")' />
          <span class="point-item-text">腹部</span>
        </div>
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_2.png")' />
          <span class="point-item-text">乳房</span>
        </div>
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_3.png")' />
          <span class="point-item-text">外阴</span>
        </div>
      </div>
      <div class="point-two">
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_4.png")' />
          <span class="point-item-text">肩颈</span>
        </div>
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_5.png")' />
          <span class="point-item-text">手臂</span>
        </div>
      </div>
      <div class="point-three">
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_6.png")' />
          <span class="point-item-text">腰背</span>
        </div>
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/luolan/point_7.png")' />
          <span class="point-item-text">腿部</span>
        </div>
      </div>
      <span class="point-etc">......</span>
    </div>
    <div id="privacy" class="content-item">
      <span class="privacy-title">三维·私密焕妍</span>
      <span class="privacy-subTitle">紧致“盆瑜伽” 焕醒私密愉悦</span>
      <div class="vi-symbol privacy-symbol"></div>
      <p class="privacy-content">首次提出“盆瑜伽”密爱私护理念<br />为女性的密爱提升提供疗程化的<br />解决方案及高端服务</p>
      <img class="privacy-img" :src='require("@/assets/img/product/luolan/privacy_img.png")' />
      <img class="privacy-img-append1" :src='require("@/assets/img/product/luolan/privacy_img_append1.png")' />
      <img class="privacy-img-append2" :src='require("@/assets/img/product/luolan/privacy_img_append2.png")' />
      <img class="privacy-img-append3" :src='require("@/assets/img/product/luolan/privacy_img_append3.png")' />
    </div>
    <div id="assess" class="content-item">
      <span class="assess-title">私密检测 全方位评估私密健康</span>
      <div class="vi-symbol assess-symbol"></div>
      <p class="assess-content">专为私密设计的检测方案，评估私密持久力、爆发力、<br />控制力、舒张力、兴奋度</p>
      <img class="assess-img" :src='require("@/assets/img/product/luolan/assess_img.png")' />
    </div>
    <div id="pattern" class="content-item">
      <span class="pattern-title">八大训练模式 科学维养私密</span>
      <div class="vi-symbol pattern-symbol"></div>
      <p class="pattern-content">“主被动结合”的科学训练方案，循序渐进，稳步修复</p>
      <img class="pattern-img" :src='require("@/assets/img/product/luolan/pattern_img.png")' />
      <div class="pattern-row">
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_1.png")' />
          <p class="pattern-item-text">神经肌肉<br />电刺激</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_2.png")' />
          <p class="pattern-item-text">肌电触发<br />电刺激</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_3.png")' />
          <p class="pattern-item-text">凯格尔模板<br />训练</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_4.png")' />
          <p class="pattern-item-text">多媒体游戏<br />训练</p>
        </div>
      </div>
      <div class="pattern-row row2">
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_5.png")' />
          <p class="pattern-item-text">牵张模板<br />训练</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_6.png")' />
          <p class="pattern-item-text">牵张放松<br />训练</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_8.png")' />
          <p class="pattern-item-text">牵张游戏<br />训练</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/luolan/pattern_7.png")' />
          <p class="pattern-item-text">音乐放松<br />训练</p>
        </div>
      </div>
    </div>
    <div id="expr" class="content-item">
      <span class="expr-title">科技加持 体验升级</span>
      <div class="vi-symbol expr-symbol"></div>
      <img class="expr-bg" :src='require("@/assets/img/product/luolan/expr_bg.png")' />
      <img class="expr-img" :src='require("@/assets/img/product/luolan/expr_img.png")' />
      <img class="expr-img-append1" :src='require("@/assets/img/product/luolan/expr_img_append_1.png")' />
      <img class="expr-img-append2" :src='require("@/assets/img/product/luolan/expr_img_append_2.png")' />
    </div>
    <div id="care" class="content-item">
      <span class="care-title">智能关怀 贴心守护</span>
      <div class="vi-symbol care-symbol"></div>
      <img class="care-img" :src='require("@/assets/img/product/luolan/care_img.png")' />
    </div>
    <div id="hommization" class="content-item">
      <span class="hommization-title">人性化设计 匠心专研</span>
      <div class="vi-symbol hommization-symbol"></div>
      <div class="hommization-item text-right" style="top: 2.41rem; right: 12.15rem;">
        <p class="hommization-item-title">复古轻奢外形</p>
        <p class="hommization-item-text">质感精致，一见倾心</p>
      </div>
      <div class="hommization-item text-right" style="top: 3.67rem; right: 13.08rem;">
        <p class="hommization-item-title">可视化LED大屏</p>
        <p class="hommization-item-text">训练状态，清晰可见</p>
      </div>
      <div class="hommization-item text-right" style="top: 4.93rem; right: 12.38rem;">
        <p class="hommization-item-title">OTA远程升级</p>
        <p class="hommization-item-text">硬件功能一键迭代</p>
      </div>
      <div class="hommization-item text-left" style="top: 2.41rem; left: 11.5rem;">
        <p class="hommization-item-title">蓝牙5.1</p>
        <p class="hommization-item-text">连接更稳定，传输更高效</p>
      </div>
      <div class="hommization-item text-left" style="top: 3.67rem; left: 12.72rem;">
        <p class="hommization-item-title">2350mAh大容量电池</p>
        <p class="hommization-item-text">超长续航，超常体验</p>
      </div>
      <div class="hommization-item text-left" style="top: 4.93rem; left: 12.16rem;">
        <p class="hommization-item-title">三通道设计</p>
        <p class="hommization-item-text">同时满足多部位修复</p>
      </div>
      <img class="hommization-img" :src='require("@/assets/img/product/luolan/hommization_img.png")' />
    </div>
    <div id="connect" class="content-item">
      <span class="connect-title">一键连接 智能训练</span>
      <div class="vi-symbol connect-symbol"></div>
      <span class="connect-content">产品通过蓝牙连接配套App，享受简单愉悦的智能训练体验</span>
      <img class="connect-circle" :src='require("@/assets/img/product/luolan/new_circle.gif")' />
      <img class="connect-img-append1" :src='require("@/assets/img/product/luolan/connect_img_append_1.png")' />
      <img class="connect-img-append2" :src='require("@/assets/img/product/luolan/connect_img_append_2.png")' />
      <img class="connect-img" :src='require("@/assets/img/product/luolan/connect_img.png")' />
    </div>
    <div id="auth" class="content-item">
      <span class="auth-title">国家医疗器械认证</span>
      <div class="vi-symbol auth-symbol"></div>
      <span class="auth-content">严苛品质</span>
      <div class="auth-img">
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/luolan/auth_img_1.png")' />
          <span class="auth-item-title">盆底康复治疗仪</span>
          <span class="auth-item-text">二类医疗器械</span>
        </div>
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/luolan/auth_img_2.png")' />
          <span class="auth-item-title">盆底康复训练软件</span>
          <span class="auth-item-text">二类医疗器械</span>
        </div>
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/luolan/auth_img_3.png")' />
          <span class="auth-item-title">阴道电极</span>
          <span class="auth-item-text">二类医疗器械</span>
        </div>
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/luolan/auth_img_4.png")' />
          <span class="auth-item-title">理疗用体表电极</span>
          <span class="auth-item-text">一类医疗器械备案</span>
        </div>
      </div>
    </div>
    <div id="talk" class="content-item">
      <span class="talk-title">全程陪伴式答疑</span>
      <div class="vi-symbol talk-symbol"></div>
      <span class="talk-content">GMAA持证康复师，在线1V1贴心指导</span>
      <img class="talk-circle" :src='require("@/assets/img/product/luolan/talk_circle.png")' />
      <img class="talk-img-append1" :src='require("@/assets/img/product/luolan/talk_img_append_1.png")' />
      <img class="talk-img-append2" :src='require("@/assets/img/product/luolan/talk_img_append_2.png")' />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
$md-color-primary: $md-color-primary-before;
$md-symbol-img-path: $md-symbol-img-path-before;

#banner {
  position: relative;
  height: 6.6rem;
  background: url(../../assets/img/product/luolan/banner-bg.png) no-repeat center center;
  background-size: 100% 100%;

  > .banner-luolan {
    position: absolute;
    top: 0.71rem;
    left: 3.15rem;
    width: 5.61rem;
    height: 5.4rem;
  }

  > .banner-title {
    position: absolute;
    top: 1.98rem;
    right: 3.6rem;
    line-height: .84rem;
    font-size: .7rem;
    font-weight: bold;
    color: $md-color-primary;
  }

  > .banner-symbol {
    top: 3.02rem;
    right: 3.6rem;
    width: .6rem;
    height: 0.1227rem;
  }

  > .banner-subTitle {
    position: absolute;
    top: 3.34rem;
    right: 3.6rem;
    line-height: .48rem;
    font-size: .4rem;
  }

  > .banner-verify {
    position: absolute;
    bottom: 0.46rem;
    right: 3.6rem;
    text-align: right;
    line-height: .17rem;
    font-size: .12rem;
    color: #999999;
  }
}

.content-item {
  position: relative;
  padding-top: 0.08rem;
  height: 6.45rem;
  background: #ffffff;
}

.vi-symbol {
  position: absolute;
  width: 0.4rem;
  height: 0.082rem;
  background-image: url('../../' + $md-symbol-img-path);
}

#apply {
  > .apply-title {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    font-size: 0.44rem;
    line-height: 0.62rem;
    font-weight: bold;
    text-align: center;
  }

  > .apply-subTitle {
    position: absolute;
    top: 1.58rem;
    left: 0;
    right: 0;
    font-size: 0.32rem;
    line-height: 0.3rem;
    text-align: center;
    color: #555;
  }

  > .apply-symbol {
    top: 2.18rem;
    left: 9.39rem;
  }

  > .apply-content {
    position: absolute;
    top: 2.41rem;
    left: 0;
    right: 0;
    font-size: 0.2rem;
    line-height: 0.3rem;
    text-align: center;
    color: #888;
  }

  > .apply-for {
    position: absolute;
    top: 3.62rem;
    left: 3.65rem;
    right: 3.75rem;
    display: flex;
    justify-content: space-between;

    > .for-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .for-item-img {
        width: 0.9rem;
      }

      > .for-item-text {
        padding-top: 0.21rem;
        font-size: 0.2rem;
        color: $md-color-primary;
        line-height: 0.26rem;
        text-align: center;
      }
    }
  }
}

#technology {
  background: #FAFBFF;

  > .technology-title {
    position: absolute;
    top: 2.56rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.42rem;
  }

  > .technology-subTitle {
    position: absolute;
    top: 3.22rem;
    left: 3.6rem;
    font-size: 0.32rem;
    color: #555;
    line-height: 0.3rem;
  }

  > .technology-symbol {
    top: 3.82rem;
    left: 3.6rem;
  }

  > .technology-img {
    position: absolute;
    top: 0.63rem;
    right: 4.1rem;
    width: 5.8rem;
    height: 5.2rem;
  }
}

#dimension {
  > .dimension-title {
    position: absolute;
    top: 2.64rem;
    left: 11.73rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .dimension-subTitle {
    position: absolute;
    top: 3.3rem;
    left: 11.73rem;
    font-size: 0.32rem;
    line-height: 0.3rem;
    color: #555;
  }

  > .dimension-symbol {
    top: 3.9rem;
    left: 11.73rem;
  }

  > .dimension-img {
    position: absolute;
    top: 0.17rem;
    left: 3.18rem;
    width: 4.35rem;
    height: 6.28rem;
  }

  > .dimension-img-append {
    position: absolute;
    top: 1.29rem;
    left: 6.05rem;
    width: 4.2rem;
    height: 4.2rem;
  }
}

#model {
  background: #FAFBFF;

  > .model-title {
    position: absolute;
    top: 2.28rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .model-subTitle {
    position: absolute;
    top: 2.94rem;
    left: 3.6rem;
    font-size: 0.32rem;
    line-height: 0.42rem;
    color: #555;
  }

  > .model-symbol {
    top: 3.66rem;
    left: 3.6rem;
  }

  > .model-content {
    position: absolute;
    top: 3.86rem;
    left: 3.6rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.32rem;
  }

  > .model-img {
    position: absolute;
    top: 0.75rem;
    right: 3.1rem;
    width: 5.7rem;
    height: 5.7rem
  }

  > .model-img-append {
    position: absolute;
    top: 0.76rem;
    right: 5.62rem;
    width: 5rem;
    height: 3.85rem
  }
}

#monitor {

  > .monitor-title {
    position: absolute;
    top: 2.58rem;
    left: 11.04rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .monitor-symbol {
    top: 3.37rem;
    left: 11.04rem;
  }

  > .monitor-content {
    position: absolute;
    top: 3.58rem;
    left: 11.04rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.3rem;
  }

  > .monitor-img {
    position: absolute;
    top: 1.2rem;
    left: 4.75rem;
    width: 5.07rem;
    height: 4rem
  }

  > .monitor-img-append {
    position: absolute;
    top: 2.75rem;
    left: 3.6rem;
    width: 3rem;
    height: 3rem
  }
}

#ck {
  background: #FAFBFF;

  > .ck-title {
    position: absolute;
    top: 2.11rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .ck-subTitle {
    position: absolute;
    top: 2.77rem;
    left: 3.6rem;
    font-size: 0.32rem;
    line-height: 0.42rem;
    color: #555;
  }

  > .ck-symbol {
    top: 3.49rem;
    left: 3.6rem;
  }

  > .ck-content {
    position: absolute;
    top: 3.7rem;
    left: 3.6rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.32rem;
  }

  > .ck-img {
    position: absolute;
    top: 0.95rem;
    right: 3.44rem;
    width: 5.2rem;
    height: 5.5rem
  }

  > .ck-img-append {
    position: absolute;
    top: 2.85rem;
    right: 7.14rem;
    width: 3rem;
    height: 3rem
  }
}

#point {

  > .point-title {
    position: absolute;
    top: 2.58rem;
    left: 11.1rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .point-symbol {
    top: 3.37rem;
    left: 11.1rem;
  }

  > .point-content {
    position: absolute;
    top: 3.58rem;
    left: 11.1rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.3rem;
  }

  > .point-img {
    position: absolute;
    top: 1.28rem;
    left: 3.48rem;
    width: 5.7rem;
    height: 5.18rem
  }

  > .point-one {
    position: absolute;
    top: 1.13rem;
    left: 6.32rem;
    display: flex;
    justify-content: space-between;
    width: 3.12rem;

    > .point-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .point-item-img {
        width: 0.8rem;
      }

      > .point-item-text {
        font-size: 0.2rem;
        color: $md-color-primary;
        padding-top: 0.1rem;
        line-height: 0.26rem;
      }
    }
  }

  > .point-two {
    position: absolute;
    top: 2.65rem;
    left: 7.48rem;
    display: flex;
    justify-content: space-between;
    width: 1.96rem;

    > .point-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .point-item-img {
        width: 0.8rem;
      }

      > .point-item-text {
        font-size: 0.2rem;
        color: $md-color-primary;
        padding-top: 0.1rem;
        line-height: 0.26rem;
      }
    }
  }

  > .point-three {
    position: absolute;
    top: 4.17rem;
    left: 7.48rem;
    display: flex;
    justify-content: space-between;
    width: 1.96rem;

    > .point-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .point-item-img {
        width: 0.8rem;
      }

      > .point-item-text {
        font-size: 0.2rem;
        color: $md-color-primary;
        padding-top: 0.1rem;
        line-height: 0.26rem;
      }
    }
  }

  > .point-etc {
    position: absolute;
    bottom: 0.56rem;
    left: 8.84rem;
    font-size: 0.2rem;
    color: $md-color-primary;
    line-height: 0.26rem;
  }
}

#privacy {
  background: #FAFBFF;

  > .privacy-title {
    position: absolute;
    top: 1.95rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .privacy-subTitle {
    position: absolute;
    top: 2.62rem;
    left: 3.6rem;
    font-size: 0.32rem;
    line-height: 0.42rem;
    color: #555;
  }

  > .privacy-symbol {
    top: 3.34rem;
    left: 3.6rem;
  }

  > .privacy-content {
    position: absolute;
    top: 3.55rem;
    left: 3.6rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.32rem;
  }

  > .privacy-img {
    position: absolute;
    top: 0.95rem;
    right: 3.4rem;
    width: 5.2rem;
    height: 5.5rem
  }

  > .privacy-img-append1 {
    position: absolute;
    top: 1.13rem;
    right: 7.23rem;
    width: 2.55rem;
    height: 1.14rem
  }

  > .privacy-img-append2 {
    position: absolute;
    top: 2.71rem;
    right: 7.23rem;
    width: 2.55rem;
    height: 1.14rem
  }

  > .privacy-img-append3 {
    position: absolute;
    top: 4.29rem;
    right: 7.23rem;
    width: 2.55rem;
    height: 1.14rem
  }
}

#assess {

  > .assess-title {
    position: absolute;
    top: 2.36rem;
    left: 9.73rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .assess-symbol {
    top: 3.26rem;
    left: 9.73rem;
  }

  > .assess-content {
    position: absolute;
    top: 3.46rem;
    left: 9.73rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.3rem;
  }

  > .assess-img {
    position: absolute;
    top: 0.94rem;
    left: 3.63rem;
    width: 4.88rem;
    height: 4.57rem
  }
}

#pattern {
  background: #FAFBFF;

  > .pattern-title {
    position: absolute;
    top: 1.01rem;
    left: 3.7rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .pattern-symbol {
    top: 1.91rem;
    left: 3.7rem;
  }

  > .pattern-content {
    position: absolute;
    top: 2.12rem;
    left: 3.6rem;
    font-size: 0.24rem;
    color: #888;
    line-height: 0.32rem;
  }

  > .pattern-img {
    position: absolute;
    top: 1.23rem;
    right: 3.6rem;
    width: 5.09rem;
    height: 4rem
  }

  > .pattern-row {
    position: absolute;
    width: 5.1rem;
    top: 2.82rem;
    left: 3.6rem;
    height: 1.16rem;
    display: flex;
    justify-content: space-between;

    > .pattern-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .pattern-item-img {
        width: 0.6rem;
      }

      > .pattern-item-text {
        padding-top: 0.12rem;
        width: 0.9rem;
        text-align: center;
        font-size: 0.18rem;
        color: $md-color-primary;
        line-height: 0.22rem;
      }
    }
  }

  > .pattern-row.row2 {
    top: 4.28rem;
  }

}

#expr {

  > .expr-title {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .expr-symbol {
    top: 1.54rem;
    left: 9.39rem;
  }

  > .expr-bg {
    position: absolute;
    top: 2.4rem;
    left: 0;
    height: 3.3rem;
  }

  > .expr-img {
    position: absolute;
    top: 2.68rem;
    left: 3.85rem;
    width: 4.3rem;
    height: 3rem
  }

  > .expr-img-append1 {
    position: absolute;
    top: 2.42rem;
    left: 9.16rem;
    width: 3rem;
    height: 3.26rem
  }

  > .expr-img-append2 {
    position: absolute;
    top: 2.42rem;
    right: 3.6rem;
    width: 3rem;
    height: 3.26rem
  }
}

#care {
  background: #FAFBFF;
  height: 7.65rem;

  > .care-title {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .care-symbol {
    top: 1.54rem;
    left: 9.39rem;
  }

  > .care-img {
    position: absolute;
    top: 2.41rem;
    left: 3.65rem;
    width: 12rem;
    height: 4.46rem
  }
}

#hommization {
  height: 6.8rem;

  > .hommization-title {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .hommization-symbol {
    top: 1.54rem;
    left: 9.39rem;
  }

  > .hommization-img {
    position: absolute;
    top: 2.62rem;
    left: 6.06rem;
    width: 6.7rem;
    height: 3.41rem
  }

  > .text-left {
    text-align: left;
  }

  > .text-right {
    text-align: right;
  }

  > .hommization-item {
    position: absolute;
    padding: 0.16rem 0.2rem;
    background: #EEF0FF;
    border-radius: 0.08rem;

    > .hommization-item-title {
      font-size: 0.22rem;
      font-weight: bold;
      color: $md-color-primary;
      line-height: 0.26rem;
    }

    > .hommization-item-text {
      margin-top: 0.09rem;
      font-size: 0.18rem;
      color: $md-color-primary;
      line-height: 0.22rem;
    }
  }

}

#connect {
  height: 6.8rem;
  background: #FAFBFF;

  > .connect-title {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .connect-symbol {
    top: 1.42rem;
    left: 9.39rem;
  }

  > .connect-content {
    position: absolute;
    top: 1.63rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    line-height: 0.3rem;
    color: #888;
  }

  > .connect-img {
    position: absolute;
    top: 3rem;
    left: 7.44rem;
    width: 4.72rem;
    height: 0.86rem;
  }

  > .connect-circle {
    width: 7.1rem;
    position: absolute;
    bottom: 0.1rem;
    left: 3.5rem;
  }

  > .connect-img-append1 {
    position: absolute;
    top: 3rem;
    left: 5rem;
    width: 3.71rem;
    height: 2.49rem;
  }

  > .connect-img-append2 {
    position: absolute;
    top: 3.17rem;
    left: 10.52rem;
    width: 3.74rem;
    height: 2.89rem;
  }
}

#auth {
  height: 7.5rem;

  > .auth-title {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .auth-symbol {
    top: 1.42rem;
    left: 9.39rem;
  }

  > .auth-content {
    position: absolute;
    top: 1.63rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    line-height: 0.33rem;
    color: #888;
  }

  > .auth-img {
    position: absolute;
    top: 2.76rem;
    left: 3.64rem;
    width: 12.02rem;
    height: 3.95rem;
    display: flex;
    justify-content: space-between;

    > .auth-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .auth-item-img {
        width: 2.59rem;
        height: 3.35rem;
      }

      > .auth-item-title {
        padding-top: 0.06rem;
        font-size: 0.18rem;
        line-height: 0.3rem;
      }

      > .auth-item-text {
        font-size: 0.14rem;
        color: #666666;
        line-height: 0.24rem;
      }
    }
  }
}

#talk {
  height: 7.5rem;
  background: #FAFBFF;

  > .talk-title {
    position: absolute;
    top: 0.77rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.42rem;
  }

  > .talk-symbol {
    top: 1.39rem;
    left: 9.39rem;
  }

  > .talk-content {
    position: absolute;
    top: 1.6rem;
    left: 0;
    right: 0;
    color: #888;
    text-align: center;
    font-size: 0.24rem;
    line-height: 0.3rem;
  }

  > .talk-circle {
    position: absolute;
    bottom: 0;
    left: 4.35rem;
    width: 10.5rem;
    height: 7.5rem;
  }

  > .talk-img-append1 {
    position: absolute;
    bottom: 0rem;
    left: 5.8rem;
    width: 7.61rem;
    height: 5.2rem;
  }

  > .talk-img-append2 {
    position: absolute;
    top: 2.75rem;
    left: 6.33rem;
    width: 3.5rem;
    height: 2.18rem;
  }
}
</style>
